let appName = '手机云管家';

var u = navigator.userAgent;

var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
if (isiOS) {
    appName = '手机云管家'
} else if (isAndroid) {
    appName = '手机云管家'
}

const agreeData = {
    'cn': [
        {
            "topTitle":"用户协议",
        },
        {
           'content':[`使用过程中遇到任何问题都可以联系我们：`]
        },
        {
            'content':[`cleanphone.feedback@gmail.com`]
        },
        {
            'content':[`欢迎使用“手机云管家”软件及相关服务！`]
        },
        {
            'content':[`为了更好地为你提供服务，请你仔细阅读“手机云管家”《用户协议》（以下简称“本协议”）。在你开始使用“手机云管家”软件及相关服务之前，请你务必认真阅读并充分理解本协议，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、法律适用和争议解决条款等。其中，免除或者限制责任条款等重要内容将以加粗形式提示你注意，你应重点阅读。如你未满18周岁，请你在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后下载本软件。`]
        },
        {
            'content':[`除非你完全接受本协议的全部内容，否则请勿下载、安装、使用（以下统称“使用”）“手机云管家”软件，或者通过任何方式使用“手机云管家”服务，或者获得“手机云管家”软件提供的任何服务 （本协议中统称“使用”）。若你使用“手机云管家”软件及相关服务，则视为你已充分理解本协议并承诺作为本协议的一方当事人接受协议的约束。`]
        },
        {
            'title': '1. 适用范围',
            'content': [
                `1.1本协议是你与本公司（以下简称“公司”）之间就你下载、安装、使用“手机云管家”软件，并获得“手机云管家”软件提供的相关服务所订立的协议。就本协议项下涉及的某些服务，可能会由公司的关联公司、控制公司向你提供，你知晓并同意接受上述服务内容，即视为接受双方之间的相关权利义务关系亦受本协议约束。`,
                `1.2“用户”指所有直接或间接获取和使用“手机云管家”软件及相关服务的使用者，包括自然人、法人和其他组织等。在本协议中称为“用户”或称“你”。`,
                '1.3“手机云管家”指由公司合法拥有并运营的、标注名称为“手机云管家”的客户端应用程序。',
                `1.4本协议内容同时包括公司已经发布及后续可能不断发布的关于“手机云管家”软件及相关服务的相关协议、规则等内容。前述内容一经正式发布，并以适当的方式送达用户（系统通知等），即为本协议不可分割的组成部分，你应同样遵守。`
            ]
        },
        {
            'title': '2. 使用“手机云管家”软件及相关服务',
            'content': [
                `2.1 你使用“手机云管家”软件及相关服务，可以通过预装、公司已授权的第三方下载等方式获取“手机云管家”客户端应用程序。若你并非从公司或经公司授权的第三方获取本软件的，公司无法保证非官方版本的“手机云管家”软件能够正常使用，你因此遭受的损失与本公司无关。`,
                `2.2 公司可能为不同的终端设备开发了不同的应用程序软件版本，你应当根据实际设备状况获取、下载、安装合适的版本。`,
                `2.3 你可根据自行需要使用“手机云管家”软件及相关服务或更新“手机云管家”版本，如你不再需要使用“手机云管家”软件及相关服务可自行卸载。`,
                `2.4 为更好的提升用户体验及服务，公司将不定期提供软件更新或改变（包括但不限于软件修改、升级、功能强化、开发新服务、软件替换等）。为保证“手机云管家”软件及相关服务安全、提升用户服务，本软件及相关服务更新或部分服务内容更新后，在可能的情况下，公司将以包括但不限于系统提示、公告、站内信等方式提示用户，用户有权选择接受更新版本或服务，如用户不接受，部分功能将受到限制或不能继续使用。`,
                '2.5除非得到公司事先书面授权，你不得以任何形式对“手机云管家”软件及相关服务进行包括但不限于改编、复制、传播、垂直搜索、镜像或交易等未经授权的访问或使用。',
                `2.6 你理解，你使用“手机云管家”软件及相关服务需自行准备与软件及相关服务有关的终端设备（如手机等），一旦你在其终端设备中打开“手机云管家”软件，即视为你使用“手机云管家”软件及相关服务。为充分实现“手机云管家”的全部功能，你可能需要将其终端设备联网，你理解由你承担所需要的费用（如流量费、上网费等）。`,
                `2.7 公司许可你个人的、不可转让的、非独占地和非商业的合法使用“手机云管家”软件及相关服务的权利。本协议未明示授权的其他一切权利仍由公司保留，你在行使该些权利时须另行获得公司的书面许可，同时公司如未行使前述任何权利，并不构成对该权利的放弃。`
            ]
        },

        {
            'title': '3. 用户行为规范',
            'content': [
                `3.1 用户行为要求`,
                `你应当对你使用本产品及相关服务的行为负责，除非法律允许或者经公司事先书面许可，你使用“手机云管家”软件及相关服务不得具有下列行为：`,
                `3.1.1 使用未经公司授权或许可的任何插件、外挂、系统或第三方工具对“手机云管家”软件及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。`,
                `3.1.2 利用或针对“手机云管家”软件及相关服务进行任何危害计算机网络安全的行为，包括但不限于：`,
                `（1）非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；`,
                `（2）提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；`,
                `（3）明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；`,
                `（4）使用未经许可的数据或进入未经许可的服务器/账号；`,
                `（5）未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；`,
                `（6）未经许可，企图探查、扫描、测试“手机云管家”系统或网络的弱点或其它实施破坏网络安全的行为；`,
                `（7）企图干涉、破坏“手机云管家”系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；`,
                `（8）伪造TCP/IP数据包名称或部分名称。`,
                `3.1.3 对“手机云管家”软件进行反向工程、反向汇编、编译或者以其他方式尝试发现本软件的源代码。`,
                `3.1.4 违反法律法规、本协议、公司的相关规则及侵犯他人合法权益的其他行为`,
                `如果我们发现或收到他人举报或投诉用户违反本协议约定的，我们有权不经通知随时对举报和投诉的内容，包括但不限于用户资料、聊天记录进行审查、删除，并视情节轻重对违规帐号处以包括但不限于警告、帐号封禁 、设备封禁 、功能封禁 的处罚，且通知用户处理结果。`,
                `3.2 用户使用“手机云管家”软件及相关服务应自觉遵守宪法法律、法规、遵守公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求。用户不得使用“手机云管家”软件及相关服务制作、复制、发布、传播含有下列内容的信息：`,
                `（1）反对宪法确定的基本原则的；`,
                `（2）危害国家安全，泄露国家秘密的`,
                `（3）颠覆国家政权，推翻社会主义制度、煽动分裂国家、破坏国家统一的；`,
                `（4）损害国家荣誉和利益的；`,
                `（5）宣扬恐怖主义、极端主义的；`,
                `（6）宣扬民族仇恨、民族歧视，破坏民族团结的；`,
                `（7）煽动地域歧视、地域仇恨的；`,
                `（8）破坏国家宗教政策，宣扬邪教和迷信的；`,
                `（9）编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序、破坏社会稳定的；`,
                `（10）散布、传播暴力、淫秽、色情、赌博、凶杀、恐怖或者教唆犯罪的；`,
                `（11）侵害未成年人合法权益或者损害未成年人身心健康的；`,
                `（12）未获他人允许，偷拍、偷录他人，侵害他人合法权利的；`,
                `（13）包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的；`,
                `（14）危害网络安全、利用网络从事危害国家安全、荣誉和利益的；`,
                `（15）侮辱或者诽谤他人，侵害他人合法权益的；`,
                `（16）对他人进行暴力恐吓、威胁，实施人肉搜索的；`,
                `（17）涉及他人隐私、个人信息或资料的；`,
                `（18）散布污言秽语，损害社会公序良俗的`,
                `（19）侵犯他人隐私权、名誉权、肖像权、知识产权等合法权益内容的；`,
                `（20）散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息；`,
                `（21）使用本网站常用语言文字以外的其他语言文字评论的；`,
                `（22）其他违反法律法规、政策及公序良俗、干扰“手机云管家”正常运营或侵犯其他用户或第三方合法权益内容的其他信息。`,
            ]
        },
        {
            'title': '4、个人信息保护',
            'content': [
                `4.1 我们与你一同致力于你个人信息的保护，保护用户个人信息是公司的基本原则之一。在使用手机云管家的过程中，你可能需要提供你的个人信息，以便我们向你提供更好的服务和相应的技术支持。你理解并同意，公司有权在遵守法律法规、本协议以及“手机云管家”《隐私政策》的前提下，获取、使用、储存和分享你的个人信息。`,
                '4.2 公司将运用加密技术、匿名化处理等其他与手机云管家相匹配的技术措施及其他安全措施保护你的个人信息，防止你的信息被不当使用或被未经授权的访问、使用或泄漏，并为前述目的建立完善的管理制度。',
                '4.3 在你使用手机云管家的过程中，我们承诺不会在未经你明确授权同意前，收集或使用你各类银行卡、第三方支付账户及对应密码等重要资料。你理解并同意，对因你的自身原因引起前述重要资料的泄露以及可能由此带来的任何损失，由你自行承担。',
                '4.4 未经你本人事先明确的授权同意，我们不会向除公司及其关联方外的任何第三方共享你的个人信息，下列情形除外：',
                `（一）你自行向第三方共享的；`,
                `（二）与国家安全、国防安全、公共安全、公共卫生、公共利益直接相关的；`,
                `（三）根据适用的法律法规的要求、强制性的行政和司法要求所必须的情况下进行披露或提供，或与犯罪侦查、起诉、审判和判决执行等直接相关的。在符合法律法规的前提下，当我们收到前述披露信息的请求时，我们会要求对方必须出具与之相应的法律文件，如传票或调查函。我们将对所有的请求都进行审慎的审查，以确保其具备合法依据，且仅限于行政、司法部门因特定调查目的有合法权利获取的数据；`,
                `（四）在法律法规允许的范围内，为维护“手机云管家”其他用户、公司及其关联方的生命、财产等重大合法权益，或为“手机云管家”软件及相关服务的安全稳定运行所必需的，例如查找、预防、处理欺诈等违法活动和减少信用风险等（不包括违反“手机云管家”《隐私政策》中所做的承诺而为获利目的对外公开或提供个人信息）；`,
                `（五）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；`,
                `（六）法律法规规定的其他情形。`,
                `4.5 如手机云管家包含链接至第三方提供的信息或其他服务（包括网站），你知悉并理解，运营该等服务的第三方可能会要求你提供相应个人信息。我们特别提醒你，需要认真阅读该等第三方的用户协议、隐私政策以及其他相关的条款，妥善保护自己的个人信息，仅在必要的情况下向该等第三方提供。本协议（以及其他与手机云管家相关的协议和规则，包括但不限于“手机云管家”《隐私政策》）不适用于任何第三方提供的服务，公司对任何因第三方使用由你提供的个人信息所可能引起的后果不承担任何法律责任。`,
                `更多关于用户个人信息保护的内容，请参看“手机云管家”《隐私政策》。`,
            ]
        },
        {
            'title': '5. “手机云管家”数据使用规范',
            'content': [
                `未经公司书面许可，用户不得自行或授权、允许、协助任何第三人对本协议“手机云管家”软件及相关服务中信息内容进行如下行为：`,
                `5.1 复制、读取、采用“手机云管家”软件及相关服务的信息内容，用于包括但不限于宣传、增加阅读量、浏览量等商业用途；`,
                `5.2 擅自编辑、整理、编排“手机云管家”软件及相关服务的信息内容后在“手机云管家”软件及相关服务的源页面以外的渠道进行展示；`,
                `5.3 采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三人对“手机云管家”软件及相关服务的的信息或内容产生流量、阅读量引导、转移、劫持等不利影响；`,
                `5.4 其他非法获取“手机云管家”软件及相关服务的信息内容的行为。`,
            ]
        },
        {
            'title': '6. 违约处理',
            'content': [
                `6.1 针对你违反本协议或其他服务条款的行为，公司有权独立判断并视情况采取措施。对涉嫌违反法律法规、涉嫌违法犯罪的行为将保存有关记录，并依法向有关主管部门报告、配合有关主管部门调查。`,
                `6.2 因你违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，你应当自行承担全部法律责任。因你的违法或违约行为导致本公司及其关联公司、控制公司向任何第三方赔偿或遭受国家机关处罚的，你还应足额赔偿本公司及其关联公司、控制公司因此遭受的全部损失。`,
                `6.3 公司尊重并保护法人、公民的知识产权、名誉权、姓名权、隐私权等合法权益。你保证，在使用“手机云管家”软件及相关服务制作、复制、发布、传播的信息时不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。针对第三方提出的全部权利主张，你应自行承担全部法律责任；如因你的侵权行为导致本公司及其关联公司、控制公司遭受损失的（包括经济、商誉等损失），你还应足额赔偿本公司及其关联公司、控制公司遭受的全部损失。`,
            ]
        },
        {
            'title': '7. 服务的变更、中断和终止',
            'content': [
                `7.1 你理解并同意，公司提供的“手机云管家”软件及相关服务是按照现有技术和条件所能达到的现状提供的。公司会尽最大努力向你提供服务，确保服务的连贯性和安全性。你理解，公司不能随时预见和防范技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵及其他各种安全问题的侵扰等原因可能导致的服务中断、数据丢失以及其他的损失和风险。`,
                `7.2 你理解并同意，公司为了服务整体运营的需要，有权在公告通知后修改、中断、中止或终止“手机云管家”软件及相关服务，而无须向用户负责或承担任何赔偿责任。`,
            ]
        },
        {
          'title': '8. 广告',
          'content' :[
              `8.1 你使用“手机云管家”软件及相关服务过程中，充分理解并同意：本服务中可能包括公司针对个人或企业推出的信息、广告发布或品牌推广服务，你同意公司有权在“手机云管家”软件及相关服务中展示“手机云管家”软件及相关服务相关和/或第三方供应商、合作伙伴的商业广告、推广或信息（包括商业或非商业信息）。`,
              `8.2 如你不同意该广告，你有权选择关闭该广告信息；“手机云管家”推送通知服务的，你有权自行关闭该服务或可停止使用“手机云管家”软件及相关服务。`,
              `8.3 公司依照法律规定履行广告及推广相关义务，你应当自行判断该广告或推广信息的真实性和可靠性并为自己的判断行为负责。除法律法规明确规定外，你因该广告或推广信息进行的购买、交易或因前述内容遭受的损害或损失，用户应自行承担，公司不予承担责任。`,
          ]
        },
        {
            'title': '9. 知识产权',
            'content':[
                `9.1公司在“手机云管家”软件及相关服务中提供的内容（包括但不限于软件、技术、程序、网页、文字、图片、图像、音频、视频、图表、版面设计、电子文档等）的知识产权属于公司所有。公司提供本服务时所依托的软件的著作权、专利权及其他知识产权均归公司所有。未经公司许可，任何人不得擅自使用（包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载“手机云管家”软件及相关服务中的内容）。`,
                `9.2 公司为“手机云管家”开发、运营提供技术支持，并对“手机云管家”软件及相关服务的开发和运营等过程中产生的所有数据和信息等享有全部权利。`,
                `9.3 请你在任何情况下都不要私自使用公司（包括但不限于 “手机云管家”等在内）的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下统称为“标识”）。未经公司事先书面同意，你不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。由于你违反本协议使用公司上述商标、标识等给公司或他人造成损失的，由你承担全部法律责任。`,
            ]

        },
        {
            'title': '10. 免责声明',
            'content':[
                `10.1 你理解并同意，“手机云管家”软件及相关服务可能会受多种因素的影响或干扰，公司不保证(包括但不限于)：`,
                `10.1.1公司完全适合用户的使用要求；`,
                `10.1.2 公司不受干扰，及时、安全、可靠或不出现错误；用户经由公司取得的任何软件、服务或其他材料符合用户的期望；`,
                `10.1.3软件中任何错误都将能得到更正。`,
                `10.2 用户理解并同意，在使用“手机云管家”软件及相关服务过程中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于政府行为、自然灾害、网络原因、黑客攻击、战争或任何其它类似事件。出现不可抗力情况时，公司将努力在第一时间及时修复，但因不可抗力给用户造成了损失，用户同意公司不承担责任。`,
                `10.3 公司依据本协议约定获得处理违法违规内容的权利，该权利不构成公司的义务或承诺，公司不能保证及时发现违法行为或进行相应处理。`,
                `10.4 用户阅读、理解并同意：关于本协议服务，公司不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。你对本协议软件及相关服务的使用行为必须自行承担相应风险。`,
                `10.5 用户阅读、理解并同意，本协议是在保障遵守国家法律法规、维护公序良俗，保护他人合法权益，公司在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证公司判断完全与司法机关、行政机关的判断一致，如因此产生的后果用户已经理解并同意自行承担。`,
            ]

        },
        {
            'title': '11. 未成年人使用条款',
            'content':[
                `11.1 若用户是未满18周岁的未成年人，应在监护人监护、指导并获得监护人同意情况下阅读本协议和使用“手机云管家”软件及相关服务。`,
                `11.2 公司重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，请在监护人指导时正确使用“手机云管家”软件及相关服务。`,
                `11.3 未成年用户理解如因你违反法律法规、本协议内容，则你及你的监护人应依照法律规定承担因此而导致的一切后果。`,
                `11.4未成年人用户特别提示：`,
                `11.4.1 青少年使用本软件及相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯。`,
                `11.4.2 青少年用户必须遵守《全国青少年网络文明公约》：`,
                `（1）要善于网上学习，不浏览不良信息；`,
                `（2）要诚实友好交流，不侮辱欺诈他人；`,
                `（3）要增强自护意识，不随意约会网友；`,
                `（4）要维护网络安全，不破坏网络秩序；`,
                `（5）要有益身心健康，不沉溺虚拟时空。`,
            ]

        },
        {
            'title': '12. 其他',
            'content':[
                `12.1 本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国大陆地区法律。倘本协议之任何规定因与中华人民共和国大陆地区的法律抵触而无效，则这些条款将尽可能接近本协议原条文意旨重新解析，且本协议其它规定仍应具有完整的效力及效果。`,
                `12.2 本协议中的相关条款会持续完善，并在软件测试和对外发布过程中，持续改进和调整。我们会将完善后的条款以适当的方式予以发布，并主动通知你查阅。前述完善后的条款一经正式发布，即作为本协议不可分割的组成部分并具有等同于本协议的法律效力。在本协议完善后，如果你继续使用手机云管家，即视为你认可并接受修改后的协议条款。如果你对修改后的条款存有异议，请你立即停止使用手机云管家。`,
                `12.4 本协议中的标题仅为方便及阅读而设，并不影响本协议中任何规定的含义或解释。`,
                `12.5 你和公司均是独立的主体，在任何情况下本协议不构成公司对用户的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。`,
                `12.6 如对本协议内容有任何疑问、意见或建议，您可以通过邮箱：cleanphone.feedback@gmail.com与我们取得联系，我们会很乐于回答您的问题`,
            ]

        },
    ],
    'en': [
        {
            'topTitle': `Terms of Service`,
        },
        {
            'content': [`The following Terms of Service and End User License Agreement (“EULA”) constitute an agreement between you (“You”) and “Clean Phone Application Provider”, governing the use of the software applications downloaded through Apple App Store or Google Play Store  (the “Licensed Application(s)”) . Questions, complaints or claims with respect to the Licensed Application should be directed to `,]
        },
        {
            'content': [`cleanphone.feedback@gmail.com`],
        },
        {
            'content':  [`You acknowledge and accept that (i) the previsions of this EULA shall not be deemed to set an agreement between You and Apple or Google(or its subsidiaries) in any manner whatsoever and (ii) Apple or Google and its subsidiaries are third party beneficiaries of this EULA, therefore Apple or Google and its subsidiaries shall have the right and are deemed to have accepted the right to enforce this EULA against You as a third party beneficiary thereof.`],
        },
        {
            'content': [`Please consider that the Licensed Application are licensed, not sold, to You for use only under the terms of this EULA, unless the same are accompanied by a different license agreement, in which case the terms of that different license agreement will govern the relationship between You and the Application Provider, subject to Your prior acceptance of that separate license agreement. The Application Provider reserves all rights not expressly granted to You hereinafter.`],
        },
        {
            'content': [`Application Provider may update the Licensed Application and/or the Services and/or this EULA from time to time in response to changing legal, technical or business developments. In such a case, Application Provider will take appropriate measures to inform You via the Licensed Application or the Services or otherwise, in accordance with the significance of the changes Application Provider makes. By continuing to access or use the Licensed Application and/or Services after updates become effective, You agree to be bound by the updated EULA.`],
        },
        {
            'title': 'a. Scope of License',
            'content': [
                `According to this EULA, the Application Provider grants you with a non-transferable license to use for personal and non-commercial use the Licensed Application on any Apple or GoogleiOS device that You own or control and in accordance with all applicable laws and Apple’s Usage Rules. This license does not allow You to use the Licensed Application on any Apple or GoogleiOS device that You do not own or control.`,
                `You may not distribute or make the Licensed Application available over a network where it could be used by multiple devices at the same time, except that such Licensed Application may be accessed and used by other accounts associated with You via us.`,
                `You may not rent, lease, lend, sell, redistribute or sublicense the Licensed Application. You may not copy (except as expressly permitted by this license and Apple’s Usage Rules), decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed Application, any updates, or any part thereof (except as and only to the extent any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open sourced components included with the Licensed Application). Any attempt to do so is a violation of the rights of the Application Provider and its licensors, if any. If You breach this restriction, You may be subject to prosecution and damages. The terms of the license will govern any upgrades provided by Application Provider that replace and/or supplement the Licensed Application, unless such upgrade is accompanied by a separate license, in which case the terms of that license shall prevail over this EULA.`,
                `You acknowledge and accept to comply with applicable third party terms of agreement related to the use of the Licensed Application.`,
                `The license covers all Apps published by us.`,
            ]
        },
        {
            'title': 'b. Consent to Use of Data',
            'content': [
                `You agree that Application Provider may collect and use technical data and related information, including but not limited to technical information about Your device, system and application software, and peripherals, that is gathered periodically to facilitate the provision of software updates, product support and other services to You (if any) related to the Licensed Application.
                   All data treated by the Application Provider shall be in compliance with EU Reg n. 679/2016, Swiss privacy laws and Application Provider’s privacy policy, as linked on the Licensed Application’s App Store or Google Play Store page.`,
                `Application Provider may use any information, as long as it is in a form that does not personally identify You, to improve its products or to provide services or technologies to You.`,
           ]
        },
        {
            'title': 'c. Termination',
            'content': [
                `This EULA shall commence on the date that you accept the same expressly or install or otherwise use the Licensed Application and shall end on the date of your disposal of the Licensed Application, save for earlier termination in accordance with this agreement. It is understood that the validity of this EULA and the validity of any purchase associated to the Licensed Application shall be construed independently from each other, this meaning that (i) the termination of such associated purchase shall not affect the validity of this EULA and (ii) the termination of this EULA shall not affect the validity of such associated purchase`,
                `Your rights under this license will terminate automatically without notice from the Application Provider if You attempt to circumvent any technical protection measures used in connection with the Licensed Application and/or Services or you otherwise use the Licensed Application and/or Services in breach of the terms of this EULA. Upon termination of the license, You shall cease all use of the Licensed Application, and destroy all copies, full or partial, of the Licensed Application.`,
            ]
        },
        {
            'title': 'd. Services and Third Party Materials',
            'content': [
                `The Licensed Application may enable access to Application Provider’s and third party services and web sites (collectively and individually, “Services”). Use of the Licensed Application and/or the Services may require Internet access and/or third party Services. In any case, the cost related to Internet access and third party Services – as well as any liability related to the above – shall be borne exclusively by You.`,
                `The Licensed Application may also enable access to certain virtual goods to be used within the Licensed Application and/or the Services. The purchase of these virtual goods shall be final and non-refundable and you acknowledge that, save if expressly allowed, the transfer of the same is prohibited. You acknowledge that (i) the Application Provider may modify or eliminate virtual goods at any time, without notice, and (ii) in case of termination of this EULA, you will have no right on the virtual goods for any reason whatsoever.`,
                `You understand that by using any of the Services, You may encounter content that may be deemed offensive, indecent, or objectionable, which content may or may not be identified as having explicit language, and that the results of any search or entering of a particular URL may automatically and unintentionally generate links or references to objectionable material. Nevertheless, You agree to use the Services at Your sole risk and that the Application Provider shall not have any liability to You for content that may be found to be offensive, indecent, or objectionable.`,
                `Certain Services may display, include or make available content, data, information, applications or materials from third parties (“Third Party Materials”) or provide links to certain third party web sites. By using the Services, You acknowledge and agree that the Application Provider is not responsible for examining or evaluating the content, accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect of such Third Party Materials or web sites. The Application Provider does not warrant or endorse and does not assume and will not have any liability or responsibility to You or any other person for any third-party Services, Third Party Materials or web sites, or for any other materials, products, or services of third parties. Third Party Materials and links to other web sites are provided solely as a convenience to You.`,
                `Financial information displayed by any Services is for general informational purposes only and is not intended to be relied upon as investment advice. Before executing any securities transaction based upon information obtained through the Services, You should consult with a financial professional. Location data provided by any Services is for basic navigational purposes only and is not intended to be relied upon in situations where precise location information is needed or where erroneous, inaccurate or incomplete location data may lead to death, personal injury, property or environmental damage.`,
                `Health information displayed by any Services is for general informational purposes only. No medical advice is provided by any Services, and the information should not be so construed or used. Using and/or accessing the Services and/or providing personal or medical information to the Application Provider or third-party Services providers does not create a physician-patient relationship between you and the Application Provider or third-party Services providers. Nothing contained in the Licensed Application is intended to create a physician-patient relationship, to replace the services of a licensed, trained physician or health professional or to be a substitute for medical advice of a physician or trained health professional licensed in your state or country. You should not rely on anything contained in the Licensed Application, and you should consult a physician licensed in your state or country in all matters relating to your health. By using the Services, you agree, represent and warrant that your physician has notified you that you are able to participate in wellness and fitness programs, workouts, exercises or any of the related activities made available to you in connection with the Services. Activities promoted by the Services may pose risks even to those who are currently in good health. You hereby agree that you shall not make any health or medical related decision based in whole or in part on anything contained in the Licensed Application. The Licensed Application (i) has not been tested with individuals with health conditions; (ii) does not provide clinical advice; (iii) shall not be intended to be a medical device or treatment and, therefore, (iv) has not been cleared or approved by any governmental body or any ministry of health of/under any jurisdiction.`,
                `Neither the Application Provider, nor any of its content providers, guarantees the availability, accuracy, completeness, reliability, or timeliness of stock information or location data displayed by any Services.`,
                `You agree that any Services contain proprietary content, information and material that is protected by applicable intellectual property and other laws, including but not limited to copyright, and that You will not use such proprietary content, information or materials in any way whatsoever except for permitted use of the Services. No portion of the Services may be reproduced in any form or by any means. You agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on the Services, in any manner, and You shall not exploit the Services in any unauthorized way whatsoever, including but not limited to, by trespass or burdening network capacity. You further agree not to use the Services in any manner to harass, abuse, stalk, threaten, defame or otherwise infringe or violate the rights of any other party, and that the Application Provider is not in any way responsible for any such use by You, nor for any harassing, threatening, defamatory, offensive or illegal messages or transmissions that You may receive as a result of using any of the Services.`,
                `In addition, third party Services and Third Party Materials that may be accessed from, displayed on or linked to from your Apple or GoogleiOS device are not available in all languages or in all countries. The Application Provider makes no representation that such Services and Materials are appropriate or available for use in any particular location. To the extent You choose to access such Services or Materials, You do so at Your own initiative and are responsible for compliance with any applicable laws, including but not limited to applicable local laws. The Application Provider, and its licensors, reserve the right to change, suspend, remove, or disable access to any Services at any time without notice. In no event will the Application Provider be liable for the removal of or disabling of access to any such Services. The Application Provider may also impose limits on the use of or access to certain Services, in any case and without notice or liability.`,

            ]
        },
        {
            'title': 'e. Ownership',
            'content': [
                `The Application Provider and/or its licensor retain all right, title and interest in and to the Licensed Application, the Services and any content of the same, including, but not limited to, all copyrights, trademarks, trade secrets, trade names, proprietary rights, patents, titles, computer codes, audiovisual effects, themes, characters, characters names, stories, dialog, settings, artwork, sound effects, musical works, and moral rights whether registered or not and all applications thereof. You hereby acknowledge that no title or ownership in the Licensed Application, the Services and any content of the same is being transferred or assigned hereby and this EULA is not to be construed as a sale of any rights in the same.`,
            ]
        },
        {
            'title': 'f. Content Restrictions',
            'content': [
                `You are solely responsible for the content that you publish or display (hereinafter, “post”) on/trough the Licensed Application and/or the Service, or transmit to other users. You will not post or transmit to other users in any way whatsoever, any defamatory, inaccurate, abusive, obscene, profane, offensive, sexually explicit, threatening, harassing, racially offensive, or illegal material, or any material that infringes or violates another party’s rights (including, but not limited to, intellectual property rights, and rights of privacy and publicity). Any content You may create, draft, design or in any way produce using the Licenced Application is for Your personal and non-commercial use only.`,
                `In case of breach of the previous obligations, You will be deemed liable and responsible for any damage or cost incurred or suffered by the Application Provider, Your profile on the Service will be permanently deleted and the greatest effort will be put into preventing you from rejoining the Service.`,
                `To the extent not prohibited by law, in no event shall Application Provider be liable for any third party claim arising from your non-compliance with this EULA and/or any applicable law. You hereby undertake to indemnify and hold Application Provider harmless from any damage, loss, cost, fee borne by the same in relation to your non-compliance with this EULA and/or any third party claim in relation to Your use of Licensed Application and/or the Service (including the content that you publish or display).`,
            ]
        },
        {
            'title': 'g. No Warranty',
            'content': [
                `You expressly acknowledge and agree that use of the Licensed Application and/or the Services is at your sole risk and that the entire risk as to satisfactory quality, performance, accuracy and effort is with you. To the maximum extent permitted by applicable law, the Licensed Application and any Services performed or provided by/via the Licensed Application are provided “as is” and “as available”, with all faults and without warranty of any kind, and application provider hereby disclaims all warranties and conditions with respect to the licensed application and any services, either express, implied or statutory, including, but not limited to, the implied warranties and/or conditions of merchantability, of satisfactory quality, of fitness for a particular purpose, of accuracy, of quiet enjoyment, and non-infringement of third party rights. Application Provider does not warrant against interference with your enjoyment of the licensed application, that the functions contained in, or services performed or provided by, the licensed application will meet your requirements, that the operation of the licensed application or services will be uninterrupted or error-free, or that defects in the licensed application or services will be corrected. No oral or written information or advice given by application provider or its authorized representative shall create a warranty. Should the Licensed Application or Services prove defective, you assume the entire cost of all necessary servicing, repair or correction.`,
                `In any case, the Application Provider shall be the sole responsible for (i) addressing any claim relating the licensed application and/or the services, including any third party’s intellectual property claim; and (ii) providing any maintenance and support service with respect to the licensed application as required under this EULA and/or applicable law.
It is understood that in the event of any failure of the Licensed Application to conform to any applicable warranties, You may notify Apple or Googleand require refund of the purchase price of the Licensed Application.`,
            ]
        },
        {
            'title': 'h. Limitation of Liability',
            'content': [
                `To the extent not prohibited by law, in no event shall Application Provider, or its licensor, be liable for personal injury, or any incidental, special, indirect or consequential damages whatsoever, including, without limitation, damages for loss of profits, loss of data, business interruption or any other commercial damages or losses, arising out of or related to your use or inability to use the licensed application, however caused, regardless of the theory of liability (contract, tort or otherwise) and even if application provider has been advised of the possibility of such damages.`,
            ]
        },
        {
            'title': 'i. Legal compliance',
            'content': [
                `You may not use or otherwise export or re-export the Licensed Application except as authorized by Swiss law or United States law and the laws of the jurisdiction in which the Licensed Application was obtained. In particular, but without limitation, the Licensed Application may not be exported or re-exported (a) into any U.S. embargoed countries or (b) to anyone on the U.S. Treasury Department’s list of Specially Designated Nationals or the U.S. Department of Commerce Denied Person’s List or Entity List.`,
                `By using the Licensed Application, you represent and warrant that you are not located in any such country or on any such list. You also agree that you will not use these products for any purposes prohibited by Swiss law and/or United States law, including, without limitation, the development, design, manufacture or production of nuclear, missiles, or chemical or biological weapons.`,
            ]
        },
        {
            'title': 'j. Miscellanea',
            'content': [
                `Application Provider may assign this EULA or any right set out herein, in whole or in part, without your consent, to any person or entity at any time with or without the Licensed Application. You may not assign or delegate any rights or obligations under this EULA without Application Provider’s previous consent, and any unauthorized assignment and delegation by you is void.`,
                `Any failure by Application Provider to exercise or enforce any of its rights under this EULA does not waive its right to enforce such right. Any waiver of such rights shall only be effective if it is in writing and signed by an authorized representative of Application Provider.`,
            ]
        },
        {
            'title': 'k. Subscriptions',
            'content':[
                `All renewable subscriptions work the following way. You tap the continue button in the subscription popup/window. You then confirm your subscription, which is either on a weekly, monthly or yearly basis. The subscription will renew and the amount will be debited from your account as long as you don’t turn off your subscription. You can do so by going to App Store or Google Play Store, tapping the icon on the top right and then going to “Subscriptions”. You can also do it from your settings. In any case, you can ask us any question cleanphone.feedback@gmail.com and we will answer.`,
            ]
        },
        {
            'title': 'l. Governing law and competent Court',
            'content': [
                `The laws of Switzerland, excluding its conflicts of law rules, shall govern this license and your use of the Licensed Application. Your use of the Licensed Application may also be subject to other local, state, national, or international laws.`,
                `Any dispute, controversy or claim arising out of or relating to this EULA or the breach, termination or validity thereof shall be finally settled at Application Provider’s discretion (i) at your domicile’s competent Court; or (ii) at the Court of Geneva (Switzerland).`,
            ]
        },
    ],
};

export { agreeData };